import React from "react";
import Seo from "../components/seo";
import { Link } from "gatsby";
import Carousel from "../components/layout/Carousel";
import useIsInViewport from "../hooks/useIsInViewport";

/* START: images import */
import magnus_art_logo from "../img/services/magnus_art_logo.svg";
import elmwood from "../img/services/elmwood.svg";
import zpevnik from "../img/services/zpevnik.svg";
import mouse_app from "../img/services/mouse_app.svg";
import kohinoor from "../img/services/kohinoor.svg";
import project_royal_mail from "../img/services/project_royal_mail.svg";
import project_mondelez from "../img/services/project_mondelez.svg";
import experience_react from "../img/services/react.svg";
import experience_angular from "../img/services/angular.svg";
import experience_laravel from "../img/services/laravel.svg";
import experience_gitlab from "../img/services/gitlab.svg";
import experience_electron from "../img/services/electron.svg";
import experience_gatsby from "../img/services/gatsby.svg";
import experience_trello from "../img/services/trello.svg";
import experience_android from "../img/services/android.svg";
import experience_ios from "../img/services/ios.svg";
import experience_nodejs from "../img/services/nodejs.svg";
import experience_slack from "../img/services/slack.svg";
import experience_mysql from "../img/services/mysql.svg";
import experience_atlassian from "../img/services/atlassian.svg";
import joalis_logo from "../img/services/logo-joalis.png";
import mpm from "../img/services/mpm.svg";
import smreflex from "../img/services/smreflex.svg";
import firebase from "../img/services/firebase.svg";
import aws from "../img/services/aws.svg";
import nextjs from "../img/services/nextjs.svg";
import swagger from "../img/services/swagger.svg";
import postman from "../img/services/postman.svg";
import docker from "../img/services/docker.svg";
import sketch from "../img/services/sketch.svg";

/* END: images import */

const carouselItems = [
    { src: experience_react, alt: "React Native" },
    { src: experience_angular, alt: "Angular" },
    { src: experience_laravel, alt: "Laravel" },
    { src: experience_nodejs, alt: "NodeJS" },
    { src: experience_gatsby, alt: "Gatsby" },
    { src: experience_electron, alt: "Electron" },
    // { src: experience_gulp, alt: "Gulp" },
    // { src: experience_cordova, alt: "Cordova" },
    { src: experience_ios, alt: "iOS" },
    { src: experience_atlassian, alt: "Atlassian" },
    { src: experience_android, alt: "Android" },
    { src: experience_gitlab, alt: "GitLab" },
    { src: experience_trello, alt: "Trello" },
    { src: experience_slack, alt: "Slack" },
    // { src: experience_docker, alt: "Docker" },
    { src: experience_mysql, alt: "MySQL" },
    // { src: jenkins, alt: "Jenkins" },
    { src: aws, alt: "AWS" },
    { src: firebase, alt: "Firebase" },
    { src: nextjs, alt: "Nextjs" },
    { src: swagger, alt: "Swagger" },
    { src: postman, alt: "Postman" },
    { src: docker, alt: "Docker" },
    { src: sketch, alt: "Sketch" },
    // { src: aws, alt: "AWS" },
    // { src: protractor, alt: "Protractor" },
    // { src: mobx, alt: "Mob X" },
    //        {src:arduino, alt:"Arduino"},
    // { src: jasmine, alt: "Jasmine" },
    // { src: raspi, alt: "Raspberry Pi" }
];

const Intro = ({ indx, setObserverRef }) => (
    <article className="article" id="SSG" ref={setObserverRef} data-observer={indx}>
        <article className="text">
            <h2 className="line animate-1">Gatsby &amp; Next JS?</h2>
            <p className="animate-2">
                Gatsby JS is a static site generator tool. Next JS is mainly a tool for server-side rendered pages. Of course, both can call APIs client side and both excell at
                performance and Seo. If this all sounds like gibberish to you, just get in touch and we will help you find the best fit for your project.
            </p>
        </article>
        <article>
            <h3 className="animate-3">Example Gatsby and Next JS Projects</h3>
            <div className="examples animate-4">
                <div>
                    <img className="animate-5" src={mpm} style={{ margin: "28px", height: "78px" }} alt="DrMouse" />
                    <h4 className="line project animate-6">Online catalogue</h4>
                    <p className="animate-7">
                        Next JS allowed us to strike best ballance between performance of static file generation and the hassle of building the whole site over and over again. Part
                        of the site is ready (as a static file) up front whilst the dynamic elements stay always up to date.
                    </p>
                    <a className="animate-8" href="https://www.mobilephonemuseum.com" target="_blank" rel="noopener noreferrer">
                        View the site
                    </a>
                </div>
                <div>
                    <img className="animate-5" src={joalis_logo} style={{ margin: "40px", maxWidth: "150px", marginTop: "10px" }} alt="Joalis" />
                    <h4 className="line project animate-6">Technological "party of the year"</h4>
                    <p className="animate-7">
                        Fully fledged{" "}
                        <a href="https://www.shop8.cz/" target="_blank" rel="noopener noreferrer">
                            shop8
                        </a>{" "}
                        experience — Fine-tuned admin dashboard, powerfull GraphQL endpoint, S3 storage, robust deployment pipeline, SSG/R website, frontshop &amp; mobile app.
                        Multiple languages, multiple product variants, multiple currencies, multiple payment gateways, multiple... You get the point.
                    </p>
                    <a className="animate-8" href="https://www.joalis.cz/" target="_blank" rel="noopener noreferrer">
                        View the site
                    </a>
                </div>
            </div>
        </article>
    </article>
);

const JamStack = ({ indx, setObserverRef }) => (
    <article className="article" id="jamstack" ref={setObserverRef} data-observer={indx}>
        <div className="text">
            <h2 className="line animate-1">Jamstack applications</h2>
            <p className="animate-2">
                Jamstack is a web development architecture based on client-side JavaScript code, reusable APIs, and markup content. The idea is easily manageable back end +
                powerful front end. Simply put, Jamstack helps us deliver superior user experiences.
            </p>
        </div>
        <h3 className="animate-3">Example Jamstack Projects</h3>
        <div className="examples animate-4">
            <div>
                <img className="animate-5" src={magnus_art_logo} style={{ margin: "25px", maxWidth: "300px" }} alt="MagnusArt" />
                <h4 className="line project animate-6">Unique portfolio site</h4>
                <p className="animate-7">
                    Milan likes to manage his portfolio via his favourite CMS however the site is unlikely to be updated frequently. This is a perfect scenario for static site
                    generator sourcing data via API of “behind the scene” Wordpress instance.
                </p>
                <a className="animate-8" href="https://magnusart.cz" target="_blank" rel="noopener noreferrer">
                    View the site
                </a>
            </div>
            <div>
                <img className="animate-5" src={elmwood} style={{ margin: "32px", width: "240px" }} alt="Elmwood" />
                <h4 className="line project animate-6">Animated presentation</h4>
                <p className="animate-7">
                    One of the best branding companies in the world let us build a snappy web site to catter for their global needs. The site utilizes all the super powers of
                    Gatsby cloud.
                </p>
                <a className="animate-8" href="https://www.elmwood.com/" target="_blank" rel="noopener noreferrer">
                    View the site
                </a>
            </div>
        </div>
    </article>
);

const MobileApps = ({ indx, setObserverRef }) => (
    <article className="article" id="mobileApps" ref={setObserverRef} data-observer={indx}>
        <div className="text">
            <h2 className="line animate-1">Mobile apps</h2>
            <p className="animate-2">
                Sharing majority of code between Android and iOS saves a lot of resources when building and maintaining your project. We rely on hybrid technologies like React
                Native to get more bang for your buck.
            </p>
        </div>
        <h3 className="animate-3">Example Mobile Apps Projects</h3>
        <div className="examples animate-4">
            <div>
                <img className="animate-5" src={mouse_app} style={{ margin: "40px", width: "85px" }} alt="Dr Mouse App" />
                <h4 className="line project animate-6">Vets in Czechia?</h4>
                <p className="animate-7">
                    Dr. Mouse is a mobile app for Android and iOS. It&nbsp;maps veterinaries in Czechia and helps you find the right vet for you pet anywhere. Even offline. Handy
                    in case of emergency, don't you think?{" "}
                </p>
                <a className="animate-8" href="https://www.drmouse.cz/aplikace" target="_blank" rel="noopener noreferrer">
                    View the site
                </a>
            </div>
            <div>
                <img className="animate-5" src={smreflex} style={{ margin: "45px", height: "70px" }} alt="Debrett’s wedding planner" />
                <h4 className="line project animate-6">SM Reflex</h4>
                <p className="animate-7">
                    Zentiva's program is aimed at patients with multiple sclerosis. The purpose of the app is to help patients to manage their disease and provide educational
                    resources which could help to improve their quality of life.
                </p>
                <a className="animate-8" href="https://smreflex.sk" target="_blank" rel="noopener noreferrer">
                    View the site
                </a>
            </div>
        </div>
    </article>
);

const BeSpoke = ({ indx, setObserverRef }) => (
    <article className="article" id="bespoke" ref={setObserverRef} data-observer={indx}>
        <div className="text">
            <h2 className="line animate-1">APIs, Backend solutions &amp; DevOps</h2>
            <p className="animate-2">
                We offer custom NodeJS and PHP programming. We do (mostly) SQL Databases, RESTful APIs, custom tasks like system audits, performance optimization, content migration
                etc. We’ve got extensive experience in the field of DevOps, CI/CD and cost efficient automatic testing.
            </p>
        </div>
        <h3 className="animate-3">Example Bespoke Projects</h3>
        <div className="examples animate-4">
            <div>
                <img className="animate-5" src={project_royal_mail} style={{ margin: "40px", width: "150px" }} alt="Royal Mail PAF" />
                <h4 className="line project animate-6">Royal Mail PAF</h4>
                <p className="animate-7">
                    With our partners at{" "}
                    <a href="http://www.dataadvance.co.uk" target="_blank" rel="noopener noreferrer">
                        Data advance
                    </a>{" "}
                    we've been involved in various projects, including Royal Mail's PAF Licensing Centre.
                </p>
                <a className="animate-8" href="http://www.poweredbypaf.com/licensing-centre/" target="_blank" rel="noopener noreferrer">
                    View the site
                </a>
                {/* https://barrocal.pt/ */}
            </div>
            <div>
                <img className="animate-5" src={project_mondelez} style={{ margin: "65px", maxWidth: "300px" }} alt="Mondelez" />
                <h4 className="line project animate-6">Interactive Map</h4>
                <p className="animate-7">A LAMP application - a tool for detailed overview of company brands with a social (networks) presence in the EU.</p>
                <a className="animate-8" href="mailto:hello@code8.cz">
                    Request a code snippet
                </a>
            </div>
        </div>
    </article>
);

const Product = ({ indx, setObserverRef }) => (
    <article className="article" id="product" ref={setObserverRef} data-observer={indx}>
        <div className="text">
            <h2 className="line animate-1">Idea making & Product Design</h2>
            <p className="animate-2">
                Every product has its own story. We are storytellers. We could be a powerful partner to you in those times when ideas come to life. You can lean on our expertise
                and borrow our skillset for your product take shape. This is rarely an easy process, but we’ve been there many times. That’s why we are confident we can make all
                the difference.
            </p>
        </div>
        <h3 className="animate-3">Example Product Design Projects</h3>
        <div className="examples animate-4">
            <div>
                <img className="animate-5" src={zpevnik} style={{ margin: "20px", height: "130px" }} alt="Zpevnik" />
                <h4 className="line project animate-6">Online Songbook</h4>
                <p className="animate-7">
                    We wanted a songbook that would be comfortable, simple, and fast. We did not find anything that would suit us. So we created our own. From the idea, through the
                    product design to the development. It is free for everyone.
                </p>
                <a className="animate-8" href="https://www.mujzpevnik.cz" target="_blank" rel="noopener noreferrer">
                    View the site
                </a>
            </div>
            <div>
                <img className="animate-5" src={kohinoor} style={{ margin: "20px", width: "150px" }} alt="Koh-i-noor" />
                <h4 className="line project animate-6">Animated Onepager</h4>
                <p className="animate-7">
                    The current design of the antibacterial crayon product lineup did not suit KOH-I-NOOR Deutschland GmbH. We decided to go back to the very beginning and create a
                    new communication design for this product line. A design that will be far more focused on children and their parents.
                </p>
                <a className="animate-8" href="http://flanaganlawrence.com" target="_blank" rel="noopener noreferrer">
                    View the site
                </a>
            </div>
        </div>
    </article>
);

const Experience = ({ indx, setObserverRef }) => (
    <article className="article" id="experience" ref={setObserverRef} data-observer={indx}>
        <div className="text">
            <h2 className="line animate-1">Platform experience</h2>
            <p className="animate-2">We also have extensive experience with the following platforms. </p>
        </div>
        <div id="experienced" className="animate-3">
            <Carousel items={carouselItems} />
        </div>
        <div className="clear"></div>
        <div className="text animate-4" style={{ position: "relative", zIndex: 500 }}>
            <br />
            <p>
                <Link to="/about">Find out more about Code&nbsp;8</Link>
            </p>
        </div>
    </article>
);

const sections = [Intro, JamStack, MobileApps, BeSpoke, Product, Experience];

const Services = () => {
    const { setRef } = useIsInViewport();
    return (
        <>
            <Seo title="Services" />
            <div>
                {sections.map((Component, index) => (
                    <Component key={index} indx={index} setObserverRef={setRef} />
                ))}
            </div>
            <div className="clear"></div>
        </>
    );
};
export default Services;
